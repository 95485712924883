import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 600000, // 10 minutos
    timeoutErrorMessage: 'timeout error'
})

instance.interceptors.request.use(
    request => request,
    error => error
)

instance.interceptors.response.use(
    response => response,
    error => Promise.reject(error.response)
)

export default instance
