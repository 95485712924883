import React, { useCallback, useState } from "react";

import api from "../../service/api";

export function HomeUsecase() {
  const [recipe, setRecipe] = useState<string>("");
  const [ingredients, setIngredients] = useState<string[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showIngredients, setShowIngredients] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    ingredients: ''
  })

  const handleRecipes = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setFormData({ingredients: ''})
      try {
        setLoading(true);
        setShowIngredients(true);
        const response = await api.post("/recipes", { ingredients });
        if (response?.data) {
          setRecipe(response.data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [ingredients]
  );

  const handleChangeIngredients = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let strArr: string[] = [];
      const str = (event.target as HTMLInputElement).value;

      setFormData({...ingredients, ingredients: str});

      if (str.indexOf(",") !== -1) strArr = str.split(",");
      else strArr.push(str);

      const arr = [...strArr];

      setIngredients(arr);
    },
    [ingredients]
  );

  const handleHideIngredients = useCallback(() => {
    setShowIngredients(false);
  }, []);

  return {
    handleRecipes,
    recipe,
    ingredients,
    handleChangeIngredients,
    loading,
    showIngredients,
    handleHideIngredients,
    formData
  };
}
