import Sidebar from "../../components/Sidebar";
import { HomeUsecase } from "./home.usecase";
import {
  Wrapper,
  Section,
  TopBar,
  MessageContainer,
  MessageContent,
  MessageText,
  Messages,
  InputSection,
  InputContainer,
  Input,
  SendButton,
} from "./styles";

import { AiOutlineSend } from "react-icons/ai";

const Home = () => {
  const {
    recipe,
    handleRecipes,
    handleChangeIngredients,
    loading,
    ingredients,
    showIngredients,
    handleHideIngredients,
    formData,
  } = HomeUsecase();

  return (
    <Wrapper>
      <Sidebar />
      <Section>
        <TopBar>Receitas</TopBar>
        <Messages>
          {ingredients && showIngredients && (
            <MessageContainer fromUser>
              <MessageContent>
                <MessageText>
                  Me informe uma receita para os ingredientes:{" "}
                  {ingredients?.join(",")}
                </MessageText>
              </MessageContent>
            </MessageContainer>
          )}
          {loading && (
            <MessageContainer>
              <MessageContent>
                <MessageText>
                  Só 1 minuto equanto procuro a receita...
                </MessageText>
              </MessageContent>
            </MessageContainer>
          )}
        {!loading && recipe && (
          <MessageContainer>
            <MessageContent>
              <MessageText>{recipe}</MessageText>
            </MessageContent>
          </MessageContainer>
        )}
        </Messages>
        <form onSubmit={handleRecipes}>
          <InputSection>
            <InputContainer>
              <Input
                onFocus={handleHideIngredients}
                onChange={handleChangeIngredients}
                value={formData.ingredients}
                placeholder="Digite seus ingredientes separados por virgula. Ex.: alho, cebola etc."
              />
              <SendButton type="submit" disabled={loading}>
                <AiOutlineSend size={24} />
              </SendButton>
            </InputContainer>
          </InputSection>
        </form>
      </Section>
    </Wrapper>
  );
};

export default Home;
