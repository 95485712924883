import styled from "styled-components";

export const Container = styled.aside`
  padding: 0.5rem;
  background: rgba(32, 33, 35);

  width: 100%;
  max-width: 300px;
  /* position: fixed; */
  height: 100%;
  left: 0;
`;

export const PreviousChat = styled.button`
  border-radius: 4px;
  padding: 15px 10px;

  transition: 0.15s;

  :hover {
    background: rgba(0, 0, 0, 0.1);
  }

  display: flex;
  gap: 5px;
`;

export const ChatName = styled.p`
  color: white;
  font-size: 16px;
`;
