import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 100vw;

  display: flex;
  justify-content: center;

  height: 100%;
`;

export const Section = styled.section`
  /* height: 100%; */
  position: relative;
  flex: 1 1 0%;

  background: #343541;
  display: flex;
  flex-direction: column;
`;

export const TopBar = styled.header`
  color: rgba(197, 197, 210, 1);
  padding: 30px;
  font-size: 18px;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;

  border-bottom: 1px solid rgba(32, 33, 35, 0.5);
`;

export const Messages = styled.div`
  overflow-y: auto;
  flex: 1 1 auto;
`;

export const MessageContainer = styled.div<{ fromUser?: boolean }>`
  padding: 20px 0;

  width: 100%;
  background: ${({ fromUser }) =>
    fromUser ? "transparent" : "rgba(68, 70, 84, 0.7)"};

  display: flex;
  justify-content: center;
`;

export const MessageContent = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  gap: 25px;
`;

export const Avatar = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 4px;
`;

export const MessageText = styled.p`
  font-size: 1rem;
  color: #ececf1;
  margin-top: 5px;
`;

export const InputSection = styled.div`
  position: absolute;
  bottom: 0;
  background-image: linear-gradient(
    180deg,
    rgba(53, 55, 64, 0),
    #353740 58.85%
  );
  width: 100%;

  display: flex;
  justify-content: center;
  padding: 20px;
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  max-width: 800px;
  border-radius: 0.75rem;
  overflow: hidden;
  border: 1px solid rgba(32, 33, 35, 0.5);
  box-shadow: 0 0 transparent, 0 0 transparent, 0 0 15px rgba(0, 0, 0, 0.1);
`;

export const Input = styled.input`
  height: 56px;
  max-height: 200px;
  padding: 1rem 3rem 1rem 1rem;
  width: 100%;
  resize: none;
  border: none;
  background: transparent;
  outline: none;
  color: white;
  font-size: 1.25rem;
`;

export const SendButton = styled.button`
  border: none;
  outline: none;
  position: absolute;
  right: 1rem;
  color: white;
  opacity: 0.4;
  background: none;
`;
